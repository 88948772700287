.spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;


    .spinner {
        display: inline-block;
        color: #1976d2;
        -webkit-animation: animation-61bdi0 1.4s linear infinite;
        -moz-animation: animation-61bdi0 1.4s linear infinite;
        -ms-animation: animation-61bdi0 1.4s linear infinite;
        animation: animation-61bdi0 1.4s linear infinite;
        width: 40px;
        height: 40px;

        svg {
            display: block;

            circle {
                color: #fff;
                stroke-width: 2px;
                stroke: currentColor;
                stroke-dasharray: 80px, 200px;
                stroke-dashoffset: 0;
                -webkit-animation: animation-1p2h4ri 1.4s ease-in-out infinite;
                -moz-animation: animation-1p2h4ri 1.4s ease-in-out infinite;
                -ms-animation: animation-1p2h4ri 1.4s ease-in-out infinite;
                animation: animation-1p2h4ri 1.4s ease-in-out infinite;
            }
        }

        @-webkit-keyframes animation-61bdi0 {
            0% {
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                transform: rotate(0deg);
            }
        
            100% {
                -webkit-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -ms-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        
        @keyframes animation-61bdi0 {
            0% {
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                transform: rotate(0deg);
            }
        
            100% {
                -webkit-transform: rotate(360deg);
                -moz-transform: rotate(360deg);
                -ms-transform: rotate(360deg);
                transform: rotate(360deg);
            }
        }
        
        @-webkit-keyframes animation-1p2h4ri {
            0% {
                stroke-dasharray: 1px, 200px;
                stroke-dashoffset: 0;
            }
        
            50% {
                stroke-dasharray: 100px, 200px;
                stroke-dashoffset: -15px;
            }
        
            100% {
                stroke-dasharray: 100px, 200px;
                stroke-dashoffset: -125px;
            }
        }
        
        @keyframes animation-1p2h4ri {
            0% {
                stroke-dasharray: 1px, 200px;
                stroke-dashoffset: 0;
            }
        
            50% {
                stroke-dasharray: 100px, 200px;
                stroke-dashoffset: -15px;
            }
        
            100% {
                stroke-dasharray: 100px, 200px;
                stroke-dashoffset: -125px;
            }
        }
    }


}